<template>
  <section id="pricing" class="pricing-section pt-130">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-lg-7 col-xl-5">
          <div class="section-title mb-60">
            <h1 class="mb-35 wow fadeInUp" data-wow-delay=".2s">Nos tarifs</h1>
            <p class="wow fadeInUp" data-wow-delay=".4s">Des solutions adaptées et adaptables</p>
          </div>
        </div>

        <div class="col-lg-5 col-xl-7">
          <ul class="nav nav-pills pricing-tab mb-60" id="pills-tab" role="tablist">
            <li role="presentation">
              <a class="pricing-tab-link active" id="pills-monthly-tab" data-toggle="pill" href="#pills-monthly" role="tab" aria-controls="pills-monthly" aria-selected="true">
                Mensuellement
              </a>
            </li>
            <li role="presentation">
              <a class="pricing-tab-link" id="pills-yearly-tab" data-toggle="pill" href="#pills-yearly" role="tab" aria-controls="pills-yearly" aria-selected="false">
                Annuellement
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
          <div class="row">
            <SinglePricing
                v-bind:pricing-img="pricingOne"
                pricing-name="Sinistros classic ONE"
                price="sur demande"
                v-bind:features="classicOne"
            />
            <SinglePricing
                v-bind:pricing-img="pricingTwo"
                pricing-name="Sinistros classic EVO"
                price="sur demande"
                v-bind:features="classicEvo"
            />
            <SinglePricing
                v-bind:pricing-img="pricingThree"
                pricing-name="Sinistros PILOT"
                price="sur demande"
                v-bind:features="pilot"
            />
          </div>
        </div>
        <div class="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab">
          <div class="row">
            <SinglePricing
                v-bind:pricing-img="pricingOne"
                pricing-name="Sinistros classic ONE"
                price="sur demande"
                v-bind:features="classicOne"
            />
            <SinglePricing
                v-bind:pricing-img="pricingTwo"
                pricing-name="Sinistros classic EVO"
                price="sur demande"
                v-bind:features="classicEvo"
            />
            <SinglePricing
                v-bind:pricing-img="pricingThree"
                pricing-name="Sinistros PILOT"
                price="sur demande"
                v-bind:features="pilot"
            />
          </div>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import SinglePricing from "./pricing/SinglePricing";
import pricingOne from "../assets/img/pricing/pricing-1.svg"
import pricingTwo from "../assets/img/pricing/pricing-2.svg"
import pricingThree from "../assets/img/pricing/pricing-3.svg"
export default {
  name: "Pricing",
  components: {SinglePricing},
  data() {
    return {
      pricingOne,
      pricingTwo,
      pricingThree,
      classicOne: [
        "Jusqu'à 10 utilisateurs",
        "Jusqu'à 1000 adresses",
        'Intégration du parc de prestataire illimité',
        "Envoi des ordres de missions",
        "Suivi des misssions",
        "Réception des devis et documents",
        'geolocalisaiton',
        'document fin chantier et réserves',
        'communication en temps réel',
        'Autres fonctionnalité possibles sur demande',
      ],
      classicEvo: [
        "Utilisateurs illimités",
        "Nombre illimité d'adresses",
        'Intégration du parc de prestataire illimité',
        "Envoi des ordres de missions",
        "Suivi des misssions",
        "Réception des devis et documents",
        'geolocalisaiton',
        'document fin chantier et réserves',
        'communication en temps réel',
        'Autres fonctionnalité possibles sur demande',
      ],
      pilot: [
        "Pilotage des missions par l'équipe gestionnaire de WHAT A FIX",
        "Réactivité garantie sous 48h grâce à la mise à disposition du parc de WHAT A FIX",
        "Réactivité garantie sous 24 ou 12h en cas d'urgence et en fonction de la typologie grâce à la mise à disposition du parc de WHAT A FIX",
        'Toutes les fonctionalités de la gamme EVO',
        'Autres fonctionnalité possibles sur demande',
      ],
    }
  }
}
</script>

<style scoped>

</style>
