<template>
  <section id="features" class="service-section pt-150">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-lg-9">
          <div class="service-content">
            <div class="section-title">
              <h1 class="mb-30 wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">Revues des principales fonctionalitées</h1>
              <p class="mb-40 wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">Passer vos ordres de missions, suivez leur évolution en temps réel, validez les documents en ligne, observez vos chantiers à travers les photos et vidéos des équipes de terrain...</p>
<!--              <a href="javascript:void(0)" class="main-btn btn-hover border-btn wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">Get Started</a>-->
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-6">
          <div class="row d-flex">
            <SingleService icon="lni-eye" title="Suivi en temps réel" desc="Vous êtes averti à chaque évolution de l'état de l'ordre de mission"/>
            <SingleService icon="lni-cloud-check" title="Accessibilité" desc="Accessible partout et sur n'importe quel support. Au travail, depuis son domicile, à midi ou à minuit..."/>
            <SingleService icon="lni-network" title="Collaboration" desc="Tous les acteurs peuvent échanger, facilitation et suivi accru"/>
            <SingleService icon="lni-share" title="Accéssibilité" desc="Des documents disponibles, partout, tout le temps"/>
            <SingleService icon="lni-add-files" title="Documents" desc="Visualisez les devis, factures, CERFA, photos, vidéos... et tout autre document nécessaire à votre activité; approuvez-les en temps réel"/>
            <SingleService icon="lni-target" title="Géolocalisation" desc="Géolocalisez les missions et les intervenants"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SingleService from "./features/SingleService";
export default {
  name: "Features",
  components: {SingleService}
}
</script>

<style scoped>

</style>
