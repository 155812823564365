<template>
  <div class="col-xl-6 col-md-6 flex-fill">
    <div class="single-service">
      <div class="icon">
        <i class="lni" v-bind:class="[icon]" style="font-size: 4rem;"></i>
      </div>
      <div class="content">
        <h3>{{ title }}</h3>
        <p>{{ desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleService",
  props: ['icon', 'title', 'desc']
}
</script>

<style scoped>

</style>
