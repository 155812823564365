<template>
  <section id="memories" class="contact-section pt-130 pb-130" v-bind:style="{backgroundImage: 'url(' + testimonial_bg + ')', backgroundSize: 'cover'}">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-xl-5">
          <div class="section-title text-center mb-60">
            <h1 class="mb-25 wow fadeInUp text-white" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">Évenements</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <Slider :slides="slides"/>
      </div>
    </div>
  </section>
</template>

<script>
import bat from '../assets/img/improvement/batisseur-avenir.png'
import viva01 from '../assets/img/improvement/vivatech-01.jpg'
import viva02 from '../assets/img/improvement/vivatech-02.png'
import viva03 from '../assets/img/improvement/vivatech-03.jpg'
import testimonial_bg from '../assets/img/testimonial-bg.svg'
import Slider from "./memories/Slider";

export default {
  name: "Memories",
  components: {Slider},
  data() {
    return {
      testimonial_bg,
      slides: [
        {
          img: bat,
          title: "WHAT A FIX est lauréate du programme de mentorat Moovjee",
          text: 'L’été 2018, WHAT A FIX devient bénéficiaire du programme Bâtisseurs d’Avenir, en partenariat avec MOOVJEE, Positive Planet, Les Déterminés, ARML et Pro BTP.',
        },
        {
          img: viva02,
          title: 'Viva Technology 2019',
          text: '',
        },
        {
          img: viva01,
          title: 'Viva Technology 2019',
          text: "WHAT A FIX est présent à l'édition 2019 de Vivatech sur le plateau du MOOVJEE",
        },
        {
          img: viva03,
          title: 'Viva Technology 2019',
          text: '',
        },
      ]
    }
  }
}
</script>
