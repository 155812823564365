<template>
  <HeroSection/>
  <Features/>
  <About/>
  <Pricing/>
  <Memories/>
  <Contact/>
</template>

<script>
// @ is an alias to /src
import HeroSection from "../components/HeroSection";
import Features from "../components/Features";
import About from "../components/About";
import Pricing from "../components/Pricing";
import Memories from "../components/Memories";
import Contact from "../components/Contact";

export default {
  name: 'Home',
  components: {
    Contact,
    Memories,
    Pricing,
    About,
    Features,
    HeroSection,
  }
}
</script>
