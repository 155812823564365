<template>
  <div class="col-lg-4 col-md-6">
    <div class="single-pricing">
      <div class="icon">
        <img v-bind:src="pricingImg" alt="">
      </div>
      <h3 class="package-name">{{ pricingName }}</h3>
      <span class="price">{{ price }}</span>
      <ul class="list-group">
        <li class="list-group-item" v-bind:key="key" v-for="(feature, key) in features">{{ feature }}</li>
      </ul>
<!--      <a href="javascript:void(0)" class="main-btn border-btn btn-hover">Get Started</a>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "SinglePricing",
  props: ['pricingImg', 'pricingName', 'price', 'features']
}
</script>

<style scoped>
</style>
