<template>
  <section id="about" class="about-section pt-150">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-img wow fadeInUp" data-wow-delay=".5s">
            <img src="assets/img/about/about-img.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content">
            <div class="section-title">
              <h1 class="wow fadeInUp" data-wow-delay=".2s">Un outil de gestion, mais pas seulement !</h1>
              <p class="wow fadeInUp" data-wow-delay=".4s">WHAT A FIX, c'est aussi :</p>
            </div>

            <div class="counter-up wow fadeInUp" data-wow-delay=".8s">
              <div class="single-counter">
                <h5>Une équipe de gestionnaires, spécialisés dans le bâtiment qui accompagne ses clients et partenaires au quotidien sur la gamme PILOT</h5>
              </div>
              <div class="single-counter position-relative">
                <h5>Une équipe de développeurs applicatifs, proactifs et hyper sympas :)</h5>
              </div>
              <div class="single-counter position-relative">
                <h5>Des agences, syndics, foncières et ERP parisiens qui pilotent les travaux de leur parc à travers l'application</h5>
              </div>
              <div class="single-counter position-relative">
                <h3 class="countup">5</h3>
                <h5>Ans d'expérience sur le secteur</h5>
              </div>
              <div class="single-counter position-relative">
                <h5>Plusieurs solutions développées en parallèle :</h5>
                <ul class="list-group mt-1">
                  <li class="list-group-item"><p class="mt-2 mb-2">Système de facturation et de suivi des règlements avec encaissement pour compte de tiers</p></li>
                  <li class="list-group-item"><p class="mt-2 mb-2">Système de génération automatique des cerfa réglementaires</p></li>
                  <li class="list-group-item"><p class="mt-2 mb-2">Outil de pilotage travaux et missions</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
