<template>
  <section id="home" class="hero-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7">
          <div class="hero-content">
            <h1 class="wow fadeInUp" data-wow-delay=".2s">La maîtrise d'œuvre du bout des doigts</h1>
<!--            <p class="wow fadeInUp" data-wow-delay=".4s">-->
<!--              Please purchase full version of the template to get all sections, features and permission to remove footer credits.-->
<!--            </p>-->
<!--            <a href="https://rebrand.ly/saasland-gg/" rel="nofollow" class="main-btn btn-hover wow fadeInUp" data-wow-delay=".6s">Purchase Now</a>-->
          </div>
        </div>
        <div class="col-lg-12">
          <div class="hero-img wow fadeInUp" data-wow-delay=".5s">
            <img v-bind:src="computer" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import computer from '../assets/img/computer-phone-trans.png';

export default {
  name: "HeroSection",
  data() {
    return {
      computer,
    }
  }
}
</script>

<style scoped>

</style>
