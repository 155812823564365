<template>
  <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="ratio: false; autoplay: true;">
    <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: 35;">
      <li v-bind:key="key" v-for="(event, key) in slides">
        <img v-bind:src="event.img" alt="" uk-cover>
        <div class="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center uk-transition-slide-bottom">
          <h3 class="uk-margin-remove">{{ event.title }}</h3>
          <p class="uk-margin-remove">{{ event.text }}</p>
        </div>
      </li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover text-dark" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover text-dark" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ['slides']
}
</script>

<style scoped src="uikit/dist/css/uikit.min.css">
</style>
